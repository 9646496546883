import { createContext } from 'react'
import { lightColorTheme, menuItems } from './constants'

const noOp = () => {}

const defaultValue = {
    currentPage: null,
    setCurrentPage: noOp,
    menuItems: menuItems,
    projectIndex: 0,
    setProjectIndex: noOp,
    colorTheme: lightColorTheme,
    setColorTheme: noOp,
    isDarkTheme: false,
    toggleColorTheme: noOp,
    homePageImageSrc:
        'https://res.cloudinary.com/dcttcffbc/image/upload/v1645304883/portfolio_images/jack-running.jpg',

    slideIndex: undefined,
    setSlideIndex: noOp,
    sectionIndex: undefined,
    setSectionIndex: noOp,
    shouldAnimateSlideTransition: true,
    setShouldAnimateSlideTransition: noOp,
    isOnBoundary: noOp,
    move: noOp,
    cursorDirection: null,
    isMobileView: false,
    isMobileDevice: false,
    fontsLoaded: false,
    posts: [],
    isBlogMode: false,
}

const SiteContext = createContext(defaultValue)

export default SiteContext
