import { StyleSheet } from 'aphrodite'

const getStyles = colorTheme => StyleSheet.create({
    footerContainer: {
        position: 'absolute',
        bottom: 0,
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        right: 0,
        pointerEvents: 'none',
    },
    blogLink: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        height: '30px',
        marginRight: '10px',
        pointerEvents: 'auto',
        fontFamily: 'Cutive Mono',
        color: colorTheme.textColor,
        ':hover': {
            color: colorTheme.accentColor,
        },
        textDecoration: 'none',
    }
})

export default getStyles
