import { useContext } from 'react'
import SiteContext from '../../../contexts/SiteContext/SiteContext'

export const Arrow = ({
    direction = 'right',
    onClick,
    color = 'black',
    isActive = true,
}) => {
    const rotation = {
        up: 270,
        right: 0,
        down: 90,
        left: 180,
    }

    const { colorTheme } = useContext(SiteContext)

    const { textColor, backgroundColor } = colorTheme
    const currentRotation = rotation[direction]

    const svgStyle = {
        transform: `rotate(${currentRotation}deg)`,
        opacity: isActive ? 1 : 0.3,
        cursor: isActive ? 'pointer' : 'default',
        PointerEvent: 'auto',
    }

    return (
        <svg
            onClick={onClick}
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            style={svgStyle}
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                fill={backgroundColor}
                cx="12"
                cy="12"
                r="11.5"
                transform="rotate(-180 12 12)"
                stroke={textColor}
            />
            <path
                d="M8.91345 16.9395L15.9566 12.0004L9.00022 6.93983"
                stroke={textColor}
            />
        </svg>
    )
}
