import { StyleSheet } from 'aphrodite'
import { styleConstants } from '../../contexts/SiteContext/constants'

const getStyles = (colorTheme, isMobileDevice) => {
    const transition = styleConstants.colorThemeTransition

    return StyleSheet.create({
        header: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '80px',
            borderBottom: `solid ${colorTheme.textColor} 3px`,
            zIndex: 999,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '0 5px',
            background: colorTheme.backgroundColor,
        },
        logo: {
            fontFamily: 'Major Mono Display, monospace', // Use Major Mono Display font
            fontSize: '2.2rem', // Set the font size to 2rem
            letterSpacing: '15px', // Set the letter spacing to 0.1em
            position: 'absolute',
            cursor: 'pointer',
            textDecoration: 'none',
            color: colorTheme.textColor,
        },
        sectionDisplayHeader: {
            // Your existing styles for sectionDisplayHeader
            fontFamily: 'Major Mono Display, monospace', // Use Major Mono Display font
            display: 'flex',
            justifyContent: 'right',
            fontSize: '2.2rem', // Set the font size to 1.5rem
            letterSpacing: '7px', // Set the letter spacing to 0.1em
            paddingRight: '5px',
            color: colorTheme.textColor,
        },
        themeButton: {
            ':hover': {
                color: colorTheme.accentColor,
            },
            position: 'absolute',
            marginTop: '5px',
            right: 0,
            color: isMobileDevice
                ? colorTheme.accentColor
                : colorTheme.textColor,
            fontSize: '2em',
            cursor: 'pointer',
            marginRight: '23px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 1,
            transition: `opacity 100ms, color ${transition}`,
        },
        hidden: {
            opacity: 0,
        },
    })
}

export default getStyles
