import { StyleSheet } from 'aphrodite'

const getStyles = (colorTheme) =>
    StyleSheet.create({
        siteWrapper: {
            width: '100vw',
            background: colorTheme.backgroundColor,
        },
    })

export default getStyles
