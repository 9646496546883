import { css } from 'aphrodite'
import React, { useContext, useEffect, useState } from 'react'
import styles from './Site.styles'
import SwipableSections from '../SwipableSections'
import { Route, Routes } from 'react-router-dom'
import Footer from '../Footer'
import Header from '../Header'
import SiteContext from '../../contexts/SiteContext/SiteContext'
import getStyles from './Site.styles'
import { Blog } from '../Blog/Blog'
import { Post } from '../Blog/Post'



const Site = () => {
    const { colorTheme, posts } = useContext(SiteContext)

    const styles = getStyles(colorTheme)


   
    return (
        <div className={css(styles.siteWrapper)}>
            <Header />
            <Routes>
                <Route
                    path=":section/:slideSlug"
                    element={<SwipableSections />}
                />
                <Route path=":section/*" element={<SwipableSections />} />
                <Route path="blog/*" element={<Post posts={posts} />} />
                <Route path="blog" element={<Blog />} />
                <Route path="blog/:slug" element={<Post />} />
                <Route path="*" element={<SwipableSections />}></Route>
            </Routes>
            <Footer />
        </div>
    )
}

export default Site
