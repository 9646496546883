import { StyleSheet } from 'aphrodite'

const styles = {
    slideContainer: {
        height: '100vh',
        // height: 'calc(100vh - 80px)',

        // height: 500,
        // marginTop: 100,
    },
    slide: {
        height: 'calc(100vh)',
        // color: '#fff',
    },
    slide1: {
        backgroundColor: '#FEA900',
    },
    slide2: {
        backgroundColor: '#B3DC4A',
    },
    slide3: {
        // height: 300,
        backgroundColor: '#6AC0FF',
    },
}

export default styles
