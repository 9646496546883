import { css } from 'aphrodite'
import React, { useContext } from 'react'
import getStyles from './SourceLinkButton.styles'
import SiteContext from '../../contexts/SiteContext/SiteContext'

const SourceLinkButton = ({ sourceLink }) => {
    const { colorTheme } = useContext(SiteContext)
    const styles = getStyles(colorTheme)

    return (
        <span>
            <a
                onClick={(e) => {
                    e.stopPropagation()
                }}
                target="_blank"
                rel="noreferrer"
                className={css(styles.sourceLink)}
                href={sourceLink}
            >
                {`</>`}
            </a>
        </span>
    )
}

export default SourceLinkButton
