import { useState } from 'react'

export const LazyLoadImage = ({
    src,
    alt,
    className,
    onLoaded,
    invert = false,
}) => {
    const handleImageLoaded = (e) => {
        setImageLoaded(true)
        if (onLoaded) {
            onLoaded(e)
        }
    }

    const [imageLoaded, setImageLoaded] = useState(false)

    const style = {
        opacity: imageLoaded ? 1 : 0,
        transition: 'opacity 1s',
        filter: invert ? 'invert(1)' : 'unset',
    }

    return (
        <img
            src={src}
            alt={alt}
            className={className}
            onLoad={handleImageLoaded}
            style={style}
        />
    )
}
