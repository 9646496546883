export const menuItems = ['recent', 'sound', 'archive']

export const lightColorTheme = {
    backgroundColor: 'white',
    borderColor: 'black',
    textColor: 'black',
    accentColor: 'red',
}

export const darkColorTheme = {
    backgroundColor: '#26283E',
    borderColor: '#EEF7F8',
    textColor: '#EEF7F8',
    accentColor: '#FFD234',
}

export const styleConstants = {
    colorThemeTransition: '200ms',
}

export const mobileBreakpoint = 700

export const DIRECTIONS = {
    up: 'up',
    down: 'down',
    left: 'left',
    right: 'right',
}

export const cursorPositions = {
    right: 'e-resize',
    down: 's-resize',
    up: 'n-resize',
    left: 'w-resize',
}
