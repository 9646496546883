import React, { useCallback, useEffect, useState } from 'react'
import {
    cursorPositions,
    darkColorTheme,
    DIRECTIONS,
    lightColorTheme,
    menuItems,
    mobileBreakpoint,
} from './constants'
import {
    getCurrentPage,
    getIsMobileDevice,
    getThemeInLocalStorage,
    setThemeInLocalStorage,
} from './helpers'
import SiteContext from './SiteContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { siteData } from '../../data/siteData'
import { throttle } from 'lodash'
import loadBlogPosts from '../../components/Blog/markdownFiles'

const SiteContextProvider = ({ children }) => {
    const [slideIndex, setSlideIndex] = useState()
    const [sectionIndex, setSectionIndex] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        if (sectionIndex === 0) {
            navigate('/home')
        } else {
            // since first section is 'home' we start from the first index
            const relativeIndex = sectionIndex - 1
            const sectionData = siteData.sections[relativeIndex]
            const slideData = sectionData?.slides[slideIndex]
            if (sectionData && slideData) {
                const sectionSlug = sectionData.slug
                const slideSlug = slideData.slug
                navigate(`/${sectionSlug}/${slideSlug}`)
            } else if (sectionData) {
                const sectionSlug = sectionData.slug
                navigate(`/${sectionSlug}`)
            }
        }
    }, [slideIndex, sectionIndex])

    const [currentPage, setCurrentPage] = React.useState(getCurrentPage())
    const [projectIndex, setProjectIndex] = React.useState(0)
    const [isDarkTheme, setIsDarkTheme] = React.useState(
        getThemeInLocalStorage()
    )

    const toggleColorTheme = React.useCallback(() => {
        setIsDarkTheme((prev) => {
            setThemeInLocalStorage(!prev)
            return !prev
        })
    }, [setIsDarkTheme])

    const [colorTheme, setColorTheme] = React.useState(
        isDarkTheme ? darkColorTheme : lightColorTheme
    )

    React.useEffect(() => {
        if (isDarkTheme) {
            setColorTheme(darkColorTheme)
        } else {
            setColorTheme(lightColorTheme)
        }
    }, [isDarkTheme, setColorTheme])

    const homePageImageSrc = React.useMemo(() => {
        if (isDarkTheme)
            return 'https://res.cloudinary.com/dcttcffbc/image/upload/v1647155505/portfolio_images/jack_void.jpg'
        else
            return 'https://res.cloudinary.com/dcttcffbc/image/upload/v1645304883/portfolio_images/jack-running.jpg'
    }, [isDarkTheme])

    const [fontsLoaded, setIsFontsLoaded] = useState(false)
    useEffect(() => {
        document.fonts.ready.then(() => {
            setIsFontsLoaded(true)
        })
    }, [])

    const [isMobileView, setIsMobileView] = useState(
        window.innerWidth < mobileBreakpoint
    )
    const [isMobileDevice, setIsMobileDevice] = useState(getIsMobileDevice())

    const [shouldAnimateSlideTransition, setShouldAnimateSlideTransition] =
        useState(isMobileDevice)

    const isOnBoundary = useCallback(
        (direction) => {
            if (sectionIndex === undefined && slideIndex === undefined)
                return true
            if (sectionIndex === 0 && direction !== 'down') return true
            if (direction === 'left' && slideIndex === 0) return true
            const maxSectionIndex = siteData.sections.length // home page is extra section
            if (direction === 'down') return sectionIndex === maxSectionIndex
            const relativeIndex = sectionIndex - 1
            const maxSlideIndexForSection =
                siteData.sections[relativeIndex].slides.length - 1
            const sectionHasNoSlides =
                siteData.sections[relativeIndex].slides.length === 0
            if (
                direction === 'right' &&
                (slideIndex === maxSlideIndexForSection || sectionHasNoSlides)
            )
                return true
            return false
        },
        [sectionIndex, slideIndex]
    )

    const move = useCallback(
        (direction) => {
            if (!isOnBoundary(direction)) {
                if (direction === 'up') {
                    // reset to first slide when section changes
                    setSlideIndex(0)
                    setSectionIndex((i) => i - 1)
                } else if (direction === 'down') {
                    setSlideIndex(0)
                    setSectionIndex((i) => i + 1)
                } else if (direction === 'right') setSlideIndex((i) => i + 1)
                else if (direction === 'left') setSlideIndex((i) => i - 1)
            }
        },
        [isOnBoundary, setSlideIndex]
    )

    const [cursorDirection, setCursorDirection] = useState(null)

    const setCurrentCursorDirection = useCallback(
        (e) => {
            const { clientX: cursorX, clientY: cursorY } = e
            const windowHeight = window.innerHeight
            const windowWidth = window.innerWidth
            const cursorInRightArea = cursorX > (windowWidth / 3) * 2
            const cursorIsInUpArea = cursorY < windowHeight / 2
            const cursorIsInLeftArea = cursorX < windowWidth / 3
            const cursorIsInDownArea = !cursorIsInUpArea
            // logic for currentCursor
            // 1. UP if cursor is in top half of page and center third and not on "up" boundary cursor, if not DOWN cursor
            // 2. RIGHT cursor is in right third and not on right boundary, if not LEFT cursor
            // 3. DOWN if cursor is in bottom half of page and center third if not UP cursor
            // 4. LEFT if cursor is in left third and not left boundary, if not RIGHT cursor

            if (cursorInRightArea && !isOnBoundary(DIRECTIONS.right)) {
                setCursorDirection(DIRECTIONS.right)
                return
            }

            if (cursorIsInLeftArea && !isOnBoundary(DIRECTIONS.left)) {
                setCursorDirection(DIRECTIONS.left)
                return
            }

            if (cursorIsInDownArea && !isOnBoundary(DIRECTIONS.down)) {
                setCursorDirection(DIRECTIONS.down)
                return
            }

            if (cursorIsInUpArea && !isOnBoundary(DIRECTIONS.up)) {
                setCursorDirection(DIRECTIONS.up)
                return
            }

            if (cursorIsInUpArea) {
                setCursorDirection(DIRECTIONS.down)
            }

            if (cursorIsInDownArea) {
                setCursorDirection(DIRECTIONS.up)
            }

            if (cursorInRightArea) {
                setCurrentCursorDirection(DIRECTIONS.left)
            }

            if (cursorIsInLeftArea) {
                setCurrentCursorDirection(DIRECTIONS.right)
            }
        },
        [isOnBoundary, setCurrentPage]
    )

    const handleKeydown = useCallback(
        (e) => {
            if (e.key === 'ArrowUp') move('up')
            if (e.key === 'ArrowDown') move('down')
            if (e.key === 'ArrowRight') move('right')
            if (e.key === 'ArrowLeft') move('left')
        },
        [move]
    )

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < mobileBreakpoint)
        }

        const throttledMouseMove = throttle(setCurrentCursorDirection, 100) // Throttle to 100ms
        window.addEventListener('resize', handleResize)
        window.addEventListener('mousemove', throttledMouseMove)
        window.addEventListener('keydown', handleKeydown)
        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('mousemove', throttledMouseMove)
            window.removeEventListener('keydown', handleKeydown)
        }
    }, [setCurrentCursorDirection, handleKeydown]) // Empty array ensures that effect is only run on mount and unmount


    // blog stuff

    const location = useLocation();
    const currentPath = location.pathname;
    const isBlogMode = currentPath.startsWith('/blog');

    const [posts, setPosts] = useState([])
    useEffect(async () => {
        if (isBlogMode) {
            const posts = await loadBlogPosts()
            setPosts(posts)
        }
    }, [isBlogMode])



    return (
        <SiteContext.Provider
            value={{
                currentPage,
                setCurrentPage,
                menuItems,
                projectIndex,
                setProjectIndex,
                colorTheme,
                toggleColorTheme,
                isDarkTheme,
                homePageImageSrc,
                sectionIndex,
                setSectionIndex,
                slideIndex,
                setSlideIndex,
                shouldAnimateSlideTransition,
                setShouldAnimateSlideTransition,
                move,
                isOnBoundary,
                cursorDirection,
                isMobileView,
                isMobileDevice,
                fontsLoaded,
                posts,
                isBlogMode,
            }}
        >
            {children}
        </SiteContext.Provider>
    )
}

export default SiteContextProvider
