import { StyleSheet } from 'aphrodite'

export const getStyles = (colorTheme, isMobileView = true) =>
    StyleSheet.create({
        slide: {
            height: '100vh',
            color: colorTheme.textColor,
            backgroundColor: colorTheme.backgroundColor,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: isMobileView ? 'start' : 'center',
            pointerEvents: 'none',
        },
        slideInnerContainer: {
            marginTop: isMobileView ? '90px' : '0',
            display: isMobileView ? 'block' : 'flex',
            flexDirection: !isMobileView ? 'row-reverse' : null,
        },
        imageContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: isMobileView ? 'calc(100vw - 50px)' : 'auto',
            height: 'auto',
            minHeight: 100,
            marginRight: isMobileView ? 0 : '50px',
        },
        image: {
            width: 'auto',
            height: 'auto',
            maxWidth: isMobileView ? 400 : '30vw',
            maxHeight: isMobileView ? 225 : '70vh',
            margin: 'auto',
            display: 'block',
            pointerEvents: 'none',
            userSelect: 'none',
        },
        imageSmall: {
            width: '100%',
            height: 'auto',
            maxWidth: isMobileView ? 100 : 300,
            maxHeight: isMobileView ? 100 : 'auto',
            margin: 'auto',
            display: 'block',
        },
        link: {
            pointerEvents: 'auto',
            textDecoration: 'none',
            color: colorTheme.textColor,
            cursor: 'pointer',
            ':hover': {
                color: colorTheme.accentColor,
            },
        },
        slideContent: {
            alignItems: 'center',
            justifyContent: 'center',
            color: colorTheme.textColor,
            width: isMobileView ? 'calc(100vw - 50px)' : '40vw',
            padding: isMobileView ? 0 : '0 50px',
            userSelect: 'none',
        },
        slideTitle: {
            textAlign: isMobileView ? 'center' : 'left',
            fontFamily: 'Major Mono Display, monospace',
            fontSize: '1.8rem',
            margin: '15px 0',
        },
        subTitle: {
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
        },
        subTitleText: {
            fontFamily: 'Major Mono Display, monospace',
            fontSize: '1rem',
        },
        slideDate: {
            fontFamily: 'Major Mono Display, monospace',
            fontSize: '1rem',
        },
        slideText: {
            fontFamily: 'Cutive Mono, monospace',
            fontSize: '1rem',
        },
        slideTags: {
            fontFamily: 'Cutive Mono, monospace',
            fontSize: '1rem',
            margin: '5px 0',
            fontStyle: 'italic',
        },
    })
