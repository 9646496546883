import { StyleSheet } from 'aphrodite'

export const getHomeStyles = (colorTheme, isMobileView) =>
    StyleSheet.create({
        homeBodyContainer: {
            display: 'flex',
            justifyContent: isMobileView ? 'center' : 'space-between',
            flexDirection: 'column',
            color: colorTheme.textColor,
            fontFamily: 'Cutive Mono, monospace',
            width: isMobileView ? 'auto' : '50vw',
            opacity: 1,
            transition: `opacity 100ms, color 100ms`,
            userSelect: 'none',
        },
        homeBodyTitle: {
            margin: '10px 0',
            textAlign: isMobileView ? 'center' : 'left',
        },
        homeBodyListItem: {
            margin: '5px 0',
        },
        homeBodyPreItem: {
            width: '86px',
            display: 'inline-block',
        },
        homeImage: {
            width: '39vw',
            height: 'auto',
            // maxWidth: isMobileView ? 400 : '30vw',
            // maxHeight: isMobileView ? 225 : '70vh',
            margin: 'auto',
            display: 'block',
            pointerEvents: 'none',
            userSelect: 'none',
        },
        hidden: {
            opacity: 0,
        },
    })
