import { StyleSheet } from 'aphrodite'

const getStyles = (colorTheme) =>
    StyleSheet.create({
        archivePage: {
            paddingTop: '100px',
            // minHeight: 'calc(100vh - 65px)',
            height: '100vh',
            userSelect: 'none',
            fontFamily: 'cutive mono, monospace',
            fontSize: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'start',
            color: colorTheme.textColor,
            backgroundColor: colorTheme.backgroundColor,
        },
        projectContainer: {
            position: 'relative',
            marginLeft: '20px',
            marginBottom: '10px',
        },
        titleContainer: {
            marginLeft: '10px',
            ':hover': {
                color: colorTheme.accentColor,
            },
        },
        link: {
            color: 'inherit',
            textDecoration: 'none',
        },
        sourceLinkContainer: {
            display: 'inline-block',
            opacity: 1,
        },
        hidden: {
            opacity: 0,
        },
        descriptionContainer: {
            display: 'inline-block',
            // width: 500,
        },
    })

export default getStyles
