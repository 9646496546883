import Site from './components/Site'
import SiteContextProvider from './contexts/SiteContext/SiteContextProvider'

function App() {
    return (
        <div className="App">
            <SiteContextProvider>
                <Site />
            </SiteContextProvider>
        </div>
    )
}

export default App
