// src/utils/markdownFetcher.js
import DOMPurify from 'dompurify';
import matter from 'gray-matter';
import { marked } from 'marked';

// hack!
(window).global = window;
global.Buffer = global.Buffer || require('buffer').Buffer;
(window).process = {
  version: ''
};

const fetchMarkdownFiles = async () => {
  const files = [
    // '2024-09-11.md',
    // '2024-09-12.md',
    // '2024-09-13.md',
  ];

  const posts = await Promise.all(
    files.map(async (fileName) => {
      const response = await fetch(`/posts/${fileName}`);
      const text = await response.text();
      const { data, content } = matter(text);
      const html = marked(content);
      return { ...data, html, fileName };
    })
  );

  return posts;
};


const loadMarkdown = async () => {
  const fetchedPosts = await fetchMarkdownFiles();
  const sortedPosts = fetchedPosts.sort((a, b) => new Date(b.date) - new Date(a.date));
  const sanitizedPosts = sortedPosts.map((post) => ({
    ...post,
    html: DOMPurify.sanitize(post.html),
  }));
  return sanitizedPosts;
};

const loadBlogPosts = async () => await loadMarkdown();

export default loadBlogPosts

