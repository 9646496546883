import { StyleSheet } from 'aphrodite'

const getStyles = (colorTheme) =>
    StyleSheet.create({
        sourceLink: {
            ':hover': {
                color: colorTheme.backgroundColor,
                background: colorTheme.textColor,
                borderColor: colorTheme.textColor,
                cursor: 'pointer',
            },
            textDecoration: 'none',
            color: 'inherit',
            border: `solid ${colorTheme.textColor} 1px`,
            padding: '2px',
            fontSize: '12px',
            fontFamily: 'Major Mono Display',
            pointerEvents: 'auto',
        },
    })

export default getStyles
