import { css } from 'aphrodite'
import SourceLinkButton from '../SourceLinkButton/SourceLinkButton'
import SiteContext from '../../contexts/SiteContext/SiteContext'
import { useContext } from 'react'
import { getStyles } from './SwipableSlide.styles'
import { LazyLoadImage } from '../LazyLoadImage/LazyLoadImage'

export const SwipableSlide = ({
    title,
    subTitle,
    description,
    imageSrc,
    slideLink,
    imageAlt,
    date,
    tags = [],
    isSlideTextCentric,
    canInvertSlideImage,
    sourceLink,
}) => {
    const { colorTheme, isDarkTheme, isMobileView } = useContext(SiteContext)
    const styles = getStyles(colorTheme, isMobileView)

    return (
        <div className={css(styles.slide)}>
            <div className={css(styles.slideInnerContainer)}>
                <div className={css(styles.imageContainer)}>
                    <LazyLoadImage
                        invert={isDarkTheme && canInvertSlideImage}
                        src={imageSrc}
                        alt={imageAlt}
                        className={
                            isSlideTextCentric
                                ? css(styles.imageSmall)
                                : css(styles.image)
                        }
                    />
                </div>
                <div className={css(styles.slideContent)}>
                    <div className={css(styles.slideTitle)}>
                        <a
                            className={css(styles.link)}
                            target="_blank"
                            href={slideLink}
                        >
                            {title}
                        </a>
                    </div>
                    <div className={css(styles.subTitle)}>
                        <div className={css(styles.slideDate)}>{date}</div>
                        <div className={css(styles.subTitleText)}>
                            {subTitle}
                        </div>
                    </div>
                    <div className={css(styles.slideText)}>{description}</div>
                    {sourceLink && (
                        <p>
                            <SourceLinkButton sourceLink={sourceLink} />{' '}
                        </p>
                    )}
                    <div className={css(styles.slideTags)}>
                        {tags.map((tag, index) => {
                            return (
                                <span key={index}>
                                    {tag}
                                    {index !== tags.length - 1 && ', '}
                                </span>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
