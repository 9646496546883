import { css } from 'aphrodite'
import React, { useContext } from 'react'
import SiteContext from '../../contexts/SiteContext/SiteContext'
import NavigationButton from '../NavigationButton'
import { Link } from 'react-router-dom'
import getStyles from './Footer.styles'
import { hover } from '@testing-library/user-event/dist/hover'

export const Footer = () => {
    const { move, isOnBoundary, isBlogMode, sectionIndex, colorTheme } = useContext(SiteContext)

    const styles = getStyles(colorTheme)

    return (
        <>
            {!isBlogMode && <div className={css(styles.footerContainer)}>
                <NavigationButton
                    label="left"
                    isActive={!isOnBoundary('left')}
                    onClick={() => move('left')}
                />
                <NavigationButton
                    label="down"
                    isActive={!isOnBoundary('down')}
                    onClick={() => move('down')}
                />
                <NavigationButton
                    label="up"
                    isActive={!isOnBoundary('up')}
                    onClick={() => move('up')}
                />
                <NavigationButton
                    label="right"
                    isActive={!isOnBoundary('right')}
                    onClick={() => move('right')}
                />
                {false && sectionIndex === 0 && <Link className={css(styles.blogLink)}  to="/blog">blog</Link>}
            </div>
            }
        </>
    )
}
