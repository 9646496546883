import { css } from 'aphrodite'
import React, { useContext } from 'react'
import SourceLinkButton from '../SourceLinkButton/SourceLinkButton'
import getStyles from './Archive.style'
import archiveData from '../../data/archiveData'
import SiteContext from '../../contexts/SiteContext/SiteContext'

export const Archive = () => {
    const [hoveredSourceLinkIndex, setHoveredSourceLinkIndex] =
        React.useState(null)

    const { colorTheme } = useContext(SiteContext)
    const isMobileView = true
    const archiveStyles = getStyles(colorTheme)
    const styles = getStyles(colorTheme)
    return (
        <div className={css(styles.slide)}>
            <div className={css(styles.slideInnerContainer)}>
                <div className={css(archiveStyles.archivePage)}>
                    {archiveData.map((project, index) => (
                        <div
                            key={project.title}
                            onMouseEnter={() =>
                                setHoveredSourceLinkIndex(index)
                            }
                            onMouseLeave={() => setHoveredSourceLinkIndex(null)}
                            className={css(archiveStyles.projectContainer)}
                        >
                            <div
                                className={
                                    hoveredSourceLinkIndex === index ||
                                    isMobileView
                                        ? css(archiveStyles.sourceLinkContainer)
                                        : css(
                                              archiveStyles.sourceLinkContainer,
                                              archiveStyles.hidden
                                          )
                                }
                            >
                                <SourceLinkButton
                                    sourceLink={project.sourceLink}
                                />
                            </div>
                            <span className={css(archiveStyles.titleContainer)}>
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    className={css(archiveStyles.link)}
                                    href={project.liveLink}
                                >
                                    {project.title}
                                </a>
                            </span>
                            <div
                                className={
                                    hoveredSourceLinkIndex === index ||
                                    isMobileView
                                        ? css(
                                              archiveStyles.descriptionContainer
                                          )
                                        : css(
                                              archiveStyles.descriptionContainer,
                                              archiveStyles.hidden
                                          )
                                }
                            >
                                {':   '}
                                {project.description}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
