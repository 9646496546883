import { css } from 'aphrodite'
import React from 'react'
import styles from './NavigationButton.styles'
import { Arrow } from '../Logos/Arrow/Arrow'

export const NavigationButton = ({ label, isActive, onClick }) => {
    return (
        <div className={css(styles.buttonContainer)}>
            {' '}
            <Arrow direction={label} isActive={isActive} onClick={onClick} />
        </div>
    )
}
