import React, { useCallback, useContext } from 'react'
import SwipeableViews from 'react-swipeable-views'
import SwipableSlide from '../SwipapbleSlide'
import { useParams } from 'react-router-dom'
import SiteContext from '../../contexts/SiteContext/SiteContext'

import { Archive } from '../Archive/Archive'

export const SwipableSection = ({ slides, sectionSlug }) => {
    const params = useParams()
    const getSlideIndex = useCallback(() => {
        const index = slides.findIndex(
            (slide) => slide.slug === params.slideSlug
        )
        return index > -1 ? index : 0
    }, [params])

    const { slideIndex, setSlideIndex, shouldAnimateSlideTransition } =
        useContext(SiteContext)

    const onChangeSlide = (index) => {
        const isNextSlideOutsideRange = index >= slides.length - 1
        if (isNextSlideOutsideRange) {
            return
        }
        setSlideIndex(index)
    }

    return (
        <SwipeableViews
            animateTransitions={shouldAnimateSlideTransition}
            index={slideIndex}
            onChangeIndex={onChangeSlide}
        >
            {sectionSlug === 'archive' ? (
                <Archive />
            ) : (
                slides.map((slide) => (
                    <SwipableSlide key={slide.title} {...slide} />
                ))
            )}
        </SwipeableViews>
    )
}
