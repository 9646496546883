import { menuItems } from './constants'

export const getCurrentPage = () => {
    const pathStrings = window.location.pathname.split('/')
    const pageName = pathStrings[1]
    if (menuItems.indexOf(pageName) > -1) {
        return pageName
    }

    return null
}

const colorThemeKey = 'isDarkTheme'
export const getThemeInLocalStorage = () => {
    const isDarkThemeValue = window.localStorage.getItem(colorThemeKey)
    if (isDarkThemeValue === 'true') return true
    return false
}

export const setThemeInLocalStorage = (isDarkTheme) => {
    window.localStorage.setItem(colorThemeKey, isDarkTheme)
}

export const getIsMobileDevice = () => {
    if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        return true
    } else return false
}
