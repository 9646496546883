import { css } from 'aphrodite'
import React, { useContext } from 'react'
import styles from './Header.styles'
import SiteContext from '../../contexts/SiteContext/SiteContext'
import { siteData } from '../../data/siteData'
import { Link, useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

import getStyles from './Header.styles'

const Logo = ({ fullTitle = true }) => {
    const name1 = fullTitle ? 'Jack' : 'J'
    const name2 = fullTitle ? 'Carrick' : 'C'
    const { setSlideIndex, setSectionIndex, colorTheme, fontsLoaded } =
        useContext(SiteContext)

    const onClick = () => {
        setSlideIndex(0)
        setSectionIndex(0)
    }

    const styles = getStyles(colorTheme)

    return (
        <Link
            onClick={onClick}
            to="/home"
            className={
                fontsLoaded ? css(styles.logo) : css(styles.logo, styles.hidden)
            }
        >
            <div className={css(styles.name)}>{name1}</div>
            <div className={css(styles.name)}>{name2}</div>
        </Link>
    )
}

const ToggleThemeButton = () => {
    const { isDarkTheme, toggleColorTheme, colorTheme, isMobileDevice } =
        useContext(SiteContext)
    const styles = getStyles(colorTheme, isMobileDevice)
    return (
        <div onClick={toggleColorTheme} className={css(styles.themeButton)}>
            {isDarkTheme ? <>&#x263C;</> : <>&#x263D;</>}
        </div>
    )
}

const SectionDisplayHeader = (sectionSlug, isLink = false) => {
    const { colorTheme, fontsLoaded } = useContext(SiteContext)
    const styles = getStyles(colorTheme)
    return (
        isLink ? <Link style={{
            textDecoration: 'none',
            color: 'inherit'
        }} to={sectionSlug}>
            <div
                className={
                    fontsLoaded
                        ? css(styles.sectionDisplayHeader)
                        : css(styles.sectionDisplayHeader, styles.hidden)
                }
            >
                {sectionSlug}
            </div>
        </Link> :
            <div
                className={
                    fontsLoaded
                        ? css(styles.sectionDisplayHeader)
                        : css(styles.sectionDisplayHeader, styles.hidden)
                }
            >
                {sectionSlug}
            </div>
    )
}
export const Header = () => {
    const { sectionIndex, colorTheme, isMobileDevice } = useContext(SiteContext)


    const location = useLocation();
    const currentPath = location.pathname;
    const isBlogPath = currentPath.startsWith('/blog');

    const currentSectionSlug = isBlogPath ?
        'blog' :
        sectionIndex === 0 ?
            'home'
            : siteData.sections[sectionIndex - 1]?.slug
    const [shouldDisplayThemeButton, setShouldDisplayThemeButton] =
        React.useState(isMobileDevice ? true : false)

    const onMouseEnter = () => {
        setShouldDisplayThemeButton(true)
    }
    const onMouseLeave = () => {
        setShouldDisplayThemeButton(false)
    }

    const styles = getStyles(colorTheme)

    return (
        <div
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={css(styles.header)}
        >
            {currentSectionSlug === 'home' ? (
                <>
                    <Logo />
                    {shouldDisplayThemeButton && <ToggleThemeButton />}
                </>
            ) : (
                <>
                    <Logo fullTitle={false} />
                    {SectionDisplayHeader(currentSectionSlug, currentSectionSlug === 'blog')}
                </>
            )}
        </div>
    )
}
