import { StyleSheet } from 'aphrodite'

const styles = StyleSheet.create({
    buttonContainer: {
        margin: '14px 2px',
        pointerEvents: 'all',
    },
})

export default styles
